<template>
  <v-container class="grey lighten-5">
    <v-row no-gutters>
      <div class="home">
        <v-row class="text-center">
          <v-col>
            <router-link
                              
                              :to="{ name: 'EditNote', params: { id: note.id } }"
                            >
                            Edit
                           
                            </router-link>
            <h2 class="display-1 font-weight-light mb-3" v-html="heading"></h2>
            
            {{note}}
            
          </v-col>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { notesRef } from "../store/firestore";
import * as moment from "moment/moment";
import router from '../router';
import store from '../store';

//  this.$forceUpdate(); is nog een probleem
//  click op v-card ook.
//  ui van de chat 
//  delete message
//  conversationNEW naar conversation?
//  gelezen of niet en notificatie
export default {
  name: "Note",

  data: () => ({
    heading: "Note",
    note: "",
    matchedWalkers: [],
    walkers: [],
    conversationId:'',
   
  }),
  created() {
    if (this.$route.params.id) {
      this.getData(this.$route.params.id);
    } else {
      this.heading = "Note not found";
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD-M-YYYY")
    },
    momentTime: function (date) {
      return moment(date).format("HH:mm op DD-M-YYYY")
    }
  },

  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  
  },
  methods: {
    imageSrc() {
     // return require("../assets/cards/" + this.randomItem(this.images));
    },

    randomItem(items) {
      return items[Math.floor(Math.random() * items.length)];
    },
    confirmWalk(partnerWalk) {
      // huidige wandeling zit op scope this.walk
      // zet de variable "partnerFound = true"
      // zet de variabe "partner = userID" op beide wanderlingen
      var that = this;
      var userWalk = this.note;
      var partnerWalkRef = notesRef.doc(partnerWalk.id);
      var userWalkRef = notesRef.doc(userWalk.id);
      partnerWalkRef
        .set(
          {
            partnerFound: true,
            partner: userWalk,
          },
          { merge: true }
        )
        .then(function () {
          that.changeCounter(1, that.user.data.uid);
          userWalkRef
            .set(
              {
                partnerFound: true,
                partner: partnerWalk,
              },
              { merge: true }
            )
            .then(function () {
              that.changeCounter(1, partnerWalk.uid);  
              //console.log("Document successfully written!");
            })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    },
    cancelWalk(partnerWalk) {
      // huidige wandeling zit op scope this.walk
      // zet de variable "partnerFound = false"
      // zet de variabe "partner = leeg" op beide wanderlingen
      var that = this;
      var userWalk = this.note;
      var partnerWalkRef = notesRef.doc(partnerWalk.id);
      var userWalkRef = notesRef.doc(userWalk.id);
      partnerWalkRef
        .set(
          {
            partnerFound: false,
            partner: {},
          },
          { merge: true }
        )
        .then(function () {
           that.changeCounter(-1, that.user.data.uid);
          userWalkRef
            .set(
              {
                partnerFound: false,
                partner: {},
              },
              { merge: true }
            )
            .then(function () {
              //gelukt
                  that.changeCounter(-1, partnerWalk.uid);  
            })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    },

    toggleEl(match) {
      match.isActive = !match.isActive;
      this.$forceUpdate();
    },
    goToChat(matchUID, walkerUID, walkId){
      this.conversationId = walkerUID+matchUID
      if(matchUID < walkerUID){
        this.conversationId = matchUID+walkerUID;
      }    
       store.dispatch("setLastWalk", walkId);
      router.push(/chat/+this.conversationId)
    },

    getData(id) {
      let that = this;
      var singleWalk = notesRef.doc(id);

      singleWalk.onSnapshot(function (doc) {
        if (doc.exists) {
          let data = doc.data();
          that.note = data;
          that.note.id = id;
          that.heading = data.title;
            
          //that.findMatches(data.datum);
        } else {
          //console.log("No such document!");
        }
      });
    },
    filterByValue(array, string) {
      return array.filter((o) =>
        Object.keys(o).some((k) =>
          o[k].toLowerCase().includes(string.toLowerCase())
        )
      );
    },
    removeBookedBYPartner(matchedWalker) {
      return matchedWalker.partnerFound !== true;
    },
    filterByValueToo(array, string) {
      return array.filter((o) => {
        return Object.keys(o).some((k) => {
          if (typeof o[k] === "string")
            return o[k].toLowerCase().includes(string.toLowerCase());
        });
      });
    },
    findMatches(datum) {
      var that = this;
      var test = [];
      test = this.filterByValueToo(this.matches, datum); // ontdubbelen
      function removeDuplicates(originalArray, prop) {
          var newArray = [];
          var lookupObject  = {};

          for(var i in originalArray) {
              lookupObject[originalArray[i][prop]] = originalArray[i];
          }

          for(i in lookupObject) {
              newArray.push(lookupObject[i]);
          }
            return newArray;
      }

      var uniqueArray = removeDuplicates(test, "id");
      that.matchedWalkers = uniqueArray.filter(this.removeBookedBYPartner);
    },
  },
};
</script>